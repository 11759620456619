import styled from 'styled-components/macro';

type LoadingScreenWrapperProps = {
  $opacity?: number;
};

export const LoadingScreenWrapper = styled.div<LoadingScreenWrapperProps>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  opacity: ${(props) => props.$opacity};
  transition: opacity 2s ease-in-out;

  video {
    max-width: 60%;
  }

  h1 {
    font-size: 20px;
    position: absolute;
    bottom: 20px;
    font-weight: 600;
    color: white;
  }
`;
