import type { Scene as SceneType } from '@babylonjs/core';
import { Color4, ParticleSystem, Texture, Vector3 } from '@babylonjs/core';

const baseParticleSystem = (scene: SceneType) => {
  const particle = new ParticleSystem('baseParticleSystem', 1000, scene);
  particle.particleTexture = new Texture('./assets/textures/flare.png');

  particle.emitter = new Vector3(0, 0.6, 0);
  particle.createDirectedCylinderEmitter(0.6, 0, 1, new Vector3(0, 1, 0), new Vector3(0, 1, 0));
  particle.updateSpeed = 0.01;
  particle.emitRate = 500;
  particle.minSize = 0.01;
  particle.maxSize = 0.018;
  particle.minLifeTime = 0.1;
  particle.maxLifeTime = 1.2;
  particle.maxEmitPower = 0.9;
  particle.minEmitPower = 0.2;
  particle.color1 = new Color4(0.141, 0.886, 1, 1);
  particle.color2 = new Color4(0, 0.4, 0.8, 1);
  particle.start();

  return particle;
};

const pixieEffect = (scene: SceneType) => {
  const particle = new ParticleSystem('boxParticleSystem', 1000, scene);
  particle.particleTexture = new Texture('./assets/textures/flare.png');

  particle.emitter = new Vector3(0, 0.8, 0);
  particle.createPointEmitter(new Vector3(0.1, -0.5, 0.1), new Vector3(-0.1, -0.5, -0.1));
  particle.updateSpeed = 0.02;
  particle.emitRate = 100;
  particle.minSize = 0.04;
  particle.maxSize = 0.06;
  particle.minLifeTime = 0.2;
  particle.maxLifeTime = 0.3;
  particle.color1 = new Color4(0.1, 0.5, 0.7, 1);
  particle.color2 = new Color4(0.1, 0.4, 0.8, 1);

  particle.start();

  return particle as ParticleSystem;
};

export { baseParticleSystem, pixieEffect };
