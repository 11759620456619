/* eslint-disable react/no-unknown-property */
import { Suspense, useEffect, useRef, useState } from 'react';
import type { AbstractMesh, ArcRotateCamera, Scene as SceneType } from '@babylonjs/core';

import { Texture, AnimationGroup } from '@babylonjs/core';
import {
  Vector3,
  Color3,
  Color4,
  GlowLayer,
  CubeTexture,
  ActionManager,
  ExecuteCodeAction,
  StandardMaterial,
} from '@babylonjs/core';
import type { SceneEventArgs } from 'react-babylonjs';
import { Model, Scene } from 'react-babylonjs';
import '@babylonjs/loaders/glTF';
import '@babylonjs/core/Debug/debugLayer';
import '@babylonjs/inspector';

import { baseParticleSystem } from './particles';
import { godrays } from './godrays';
import { cardAnimation, cameraAnimation } from './animations';

const CARD_WIDTH = 0.14;
const CARD_HEIGHT = CARD_WIDTH / 1.56;
const CARD_START_ROTATION = new Vector3(0, Math.PI, Math.PI);
const CARD_START_POSITION = new Vector3(0, 0.8, 0);

function GreetingCard() {
  return (
    <plane
      name="greetingCard"
      width={CARD_WIDTH}
      height={CARD_HEIGHT}
      rotation={CARD_START_ROTATION}
      position={CARD_START_POSITION}
      setEnabled={false}
    ></plane>
  );
}

type CyberboxProps = {
  setLoadingOpacity: (value: number) => void;
};

function Cyberbox({ setLoadingOpacity }: CyberboxProps) {
  const [scene, setScene] = useState<SceneType>();
  const [loadMeshes, setLoadMeshes] = useState<boolean>(false);
  const sceneCamera = useRef<ArcRotateCamera>();

  const animationGroups = useRef<AnimationGroup[]>();
  const isOpen = useRef<boolean>(false);
  const cardRef = useRef<AbstractMesh>();
  const cardTexture = useRef<Texture>();

  const setupScene = ({ scene }: SceneEventArgs) => {
    scene.useRightHandedSystem = true;
    scene.clearColor = new Color4(0.043, 0.043, 0.1, 1);
    setScene(scene);
    scene.onReadyObservable.add(() => {
      const envMap = new CubeTexture('./assets/textures/snowy.env', scene);
      scene.environmentTexture = envMap;
      scene.environmentTexture.level = 1;

      const gl = new GlowLayer('glow', scene);
      gl.intensity = 0.6;
      setTimeout(() => {
        setLoadingOpacity(0);
        setTimeout(() => {
          if (!sceneCamera.current) return;
          scene.beginAnimation(sceneCamera.current, 0, 100, false, 1);
        }, 2000);
      }, 4000);
    });
  };

  useEffect(() => {
    setLoadMeshes(true);
    if (!scene) return;
    // scene.debugLayer.show();
    animationGroups.current = scene.animationGroups;
    const card = scene.getMeshByName('greetingCard');
    if (!card) return;
    cardRef.current = card;

    const img = document.createElement('img');
    img.src = './assets/textures/card/card.jpg';
    const texture = new Texture(img.src, scene, false, false);
    cardTexture.current = texture;
  }, [scene]);

  const setupCamera = (camera: ArcRotateCamera) => {
    camera.animations.push(...cameraAnimation(camera));
    sceneCamera.current = camera;
  };

  const createActions = (scene: SceneType) => {
    const actionManager = new ActionManager(scene);
    if (!animationGroups.current) return;
    const openAnimation = animationGroups.current[0];
    const closeAnimation = animationGroups.current[1];
    const cardAnimation = animationGroups.current[3];
    actionManager.registerAction(
      new ExecuteCodeAction(ActionManager.OnPickDownTrigger, function () {
        if (isOpen.current) return;
        isOpen.current = true;
        openAnimation.play();
      }),
    );

    openAnimation.onAnimationEndObservable.add(() => {
      cardRef.current?.setEnabled(true);
      cardAnimation.play();
      setTimeout(() => {
        cardRef.current?.setEnabled(false);
        closeAnimation.play();
      }, 8000);
    });

    closeAnimation.onAnimationEndObservable.add(() => {
      isOpen.current = false;
    });

    return actionManager;
  };

  const setupCyberbox = (rings: AbstractMesh) => {
    if (!scene) return;
    scene.stopAllAnimations();
    const root = rings.getChildMeshes()[0];
    root.name = 'cyberboxRoot';
    if (!animationGroups.current || !cardRef.current) return;

    const cardAnimationGroup = new AnimationGroup('cardAnimationGroup');
    const cardAnimationTargets = cardAnimation(cardRef.current);
    for (const animation of cardAnimationTargets) {
      cardAnimationGroup.addTargetedAnimation(animation, cardRef.current);
    }

    const flakeAnimation = animationGroups.current[2];
    flakeAnimation.play(true);
    const actions = createActions(scene);
    if (!actions) return;
    for (const child of root.getChildMeshes()) {
      if (child.name.includes('base')) {
        child.actionManager = actions;
      }
    }
    baseParticleSystem(scene);
    if (!sceneCamera.current) return;
    godrays(sceneCamera.current, scene);

    if (!cardTexture.current) return;

    const cardMaterial = new StandardMaterial('greetingCard_material', scene);
    cardMaterial.diffuseTexture = cardTexture.current;
    cardMaterial.backFaceCulling = false;
    cardMaterial.emissiveColor = new Color3(0, 0.2117, 0.4);
    // cardMaterial.opacityTexture = new Texture('./assets/textures/card/card_a.png', scene, false, false);
    cardRef.current.material = cardMaterial;
  };

  return (
    <Scene
      clearColor={new Color4(0, 0.165, 0.231, 1)}
      onSceneMount={(scene: SceneEventArgs) => {
        setupScene(scene);
      }}
    >
      <arcRotateCamera
        name="arcRotateCamera"
        target={new Vector3(0, 0.8, 0)}
        alpha={Math.PI / 2}
        beta={0}
        radius={3}
        lowerRadiusLimit={0.5}
        minZ={0.01}
        panningSensibility={0}
        wheelPrecision={100}
        pinchPrecision={100}
        onCreated={(camera: ArcRotateCamera) => {
          setupCamera(camera);
        }}
      />
      <hemisphericLight
        name="hemisphericLight"
        direction={new Vector3(0, 1, 0)}
        specular={new Color3(0, 0, 0)}
        diffuse={new Color3(0.1, 0.5, 0.8)}
        groundColor={new Color3(0.1, 0.5, 0.8)}
        intensity={2}
      />

      <Suspense fallback={<plane name="plane" size={0.2}></plane>}>
        {loadMeshes && (
          <Model
            name="cyberbox"
            sceneFilename="cyberbox.glb"
            rootUrl="./assets/mesh/"
            isPickable={true}
            onCreated={(cyberbox: AbstractMesh) => setupCyberbox(cyberbox)}
          ></Model>
        )}
        <GreetingCard />
      </Suspense>
    </Scene>
  );
}

export default Cyberbox;
