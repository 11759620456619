import { useEffect, useState } from 'react';
import { Engine } from 'react-babylonjs';
import Cyberbox from '../scenes/Cyberbox';
import { CanvasWrapper } from './RenderingCanvas.css';
import LoadingScreen from '../components/LoadingScreen/LoadingScreen';

export default function RenderingCanvas() {
  const [loader, setLoader] = useState<boolean>(true);
  const [loadingOpacity, setLoadingOpacity] = useState<number>(1);

  useEffect(() => {
    if (loadingOpacity === 0) {
      setTimeout(() => setLoader(false), 2000);
    }
  }, [loadingOpacity]);

  return (
    <CanvasWrapper>
      {loader && <LoadingScreen opacity={loadingOpacity} />}
      <Engine antialias adaptToDeviceRatio canvasId="babylonJS">
        <Cyberbox setLoadingOpacity={setLoadingOpacity} />
      </Engine>
    </CanvasWrapper>
  );
}
