import { LoadingScreenWrapper } from './LoadingScreen.css';

type LoadingScreenProps = {
  opacity: number;
};
export default function LoadingScreen({ opacity }: LoadingScreenProps) {
  return (
    <LoadingScreenWrapper $opacity={opacity}>
      <video src={'./assets/textures/loading.mp4'} autoPlay={true} muted={true}></video>
      <h1>LOADING...</h1>
    </LoadingScreenWrapper>
  );
}
